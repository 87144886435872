import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _049e3fad = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2563ddd0 = () => interopDefault(import('~/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _049e3fad,
    name: "index"
  }, {
    path: "/_preview/:slug/:nonce",
    component: _2563ddd0,
    name: "preview"
  }, {
    path: "/products/:slug/:variant?",
    component: _2563ddd0,
    name: "product"
  }, {
    path: "/families/:slug",
    component: _2563ddd0,
    name: "family"
  }, {
    path: "/upholstery/:slug/:variant?",
    component: _2563ddd0,
    name: "upholstery"
  }, {
    path: "/:pagetype/:slug",
    component: _2563ddd0,
    name: "subpage"
  }, {
    path: "/:slug*",
    component: _2563ddd0,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

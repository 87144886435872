import Vue from 'vue'
import Router from 'vue-router'
import vClickOutside from 'v-click-outside'
import AsyncComputed from 'vue-async-computed'
import VueScrollTo from 'vue-scrollto'
import VueMq from 'vue-mq'
import vTinySlider from 'vue-tiny-slider'
// eslint-disable-next-line import/no-named-as-default
import VuePlyr from '@skjnldsv/vue-plyr'
import VueWaypoint from 'vue-waypoint'
import { Plugin } from 'vue-fragment'
import vueDebounce from 'vue-debounce'
import vSelect from 'vue-select'
import VueLazyload from 'vue-lazyload'
import VScrollLock from 'v-scroll-lock'

Vue.use(VueLazyload, {
  attempt: 0
})
Vue.use(VScrollLock)
Vue.component('v-select', vSelect)
Vue.use(Plugin)
Vue.use(Router)
Vue.use(vClickOutside)
Vue.use(AsyncComputed)
Vue.use(vueDebounce)
Vue.use(VuePlyr)
Vue.use(VueWaypoint)
const VueTinySlider = {
  install(Vue, options) {
    Vue.component('tiny-slider', vTinySlider)
  }
}
Vue.use(VueTinySlider)
export default ({ app: { store } }) => {
  Vue.use(VueScrollTo, {
    easing: [0.77, 0.0, 0.175, 1.0],
    cancelable: false
  })

  Vue.use(VueMq, {
    breakpoints: {
      tiny: 450,
      mobile: 600,
      small: 800,
      medium: 1024,
      large: 1200
    }
  })

  Vue.directive('vpshow', {
    twoWay: true,
    inViewport(el) {
      const rect = el.getBoundingClientRect()
      return !(
        rect.bottom < 0 ||
        rect.right < 0 ||
        rect.left > window.innerWidth ||
        rect.top > window.innerHeight
      )
    },

    bind(el, binding) {
      if (binding.value) {
        el.setAttribute('animation-type', binding.value.animation)
      } else if (store.state.siteOptions.animation_type) {
        el.setAttribute(
          'animation-type',
          store.state.siteOptions.animation_type
        )
      }
      if (binding.value) {
        el.style['transition-duration'] = '500ms'
      } else if (store.state.siteOptions.animation_duration) {
        el.style['transition-duration'] =
          store.state.siteOptions.animation_duration + 'ms'
      }

      if (binding.value) {
      } else if (
        store.state.siteOptions.animation_amount &&
        store.state.siteOptions.animation_type
      ) {
        if (store.state.siteOptions.animation_type === 'slideup') {
          el.style.transform =
            'translate(0,' + store.state.siteOptions.animation_amount + 'px)'
        }
      }
      if (store.state.siteOptions.animation_amount) {
        el.setAttribute(
          'animation-amount',
          store.state.siteOptions.animation_amount
        )
      }
      if (store.state.siteOptions.animation_ease) {
        el.setAttribute(
          'animation-ease',
          store.state.siteOptions.animation_ease
        )
      }
      el.classList.add('before-enter')
      el.$onScroll = function() {
        if (binding.def.inViewport(el)) {
          el.classList.add('enter')
          el.classList.remove('before-enter')
          binding.def.unbind(el, binding)
        }
      }
    },

    inserted(el, binding) {
      if (window) {
        if (el.closest('#search')) {
          document
            .getElementById('search')
            .addEventListener('scroll', el.$onScroll, { passive: true })
        } else {
          document.addEventListener('scroll', el.$onScroll, { passive: true })
        }
        window.scrollTo(window.scrollX, window.scrollY - 1)
        window.scrollTo(window.scrollX, window.scrollY + 1)
        el.$onScroll()
      }
    },

    unbind(el, binding) {
      if (el.closest('#search')) {
        document
          .getElementById('search')
          .removeEventListener('scroll', el.$onScroll)
      } else {
        document.removeEventListener('scroll', el.$onScroll)
      }
      delete el.$onScroll
    }
  })
}

<template>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <!-- Background of PhotoSwipe.
		It's a separate element as animating opacity is faster than rgba(). -->
    <div :ref="'bg'" class="pswp__bg" />

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
			PhotoSwipe keeps only 3 of them in the DOM to save memory.
			Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container" :style="[generalFont]">
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>

      <!-- Default (PhotoSwipeUIDefault) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter" />

          <button
            class="pswp__button pswp__button--close"
            title="Close (Esc)"
          />

          <button class="pswp__button pswp__button--share" title="Share" />

          <button
            class="pswp__button pswp__button--fs"
            title="Toggle fullscreen"
          />

          <button class="pswp__button pswp__button--zoom" title="Zoom in/out" />

          <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
        >
          <div class="pswp__share-tooltip" />
        </div>

        <button
          class="pswp__button pswp__button--arrow--left"
          title="Previous (arrow left)"
        />

        <button
          class="pswp__button pswp__button--arrow--right"
          title="Next (arrow right)"
        />

        <div :style="[generalFont]" class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import bus from './bus'

export default {
  name: 'Teaser',

  computed: {
    generalFont() {
      return this.$store.getters.generalStyle
    }
  },

  mounted() {
    const self = this
    const initPhotoSwipeFromDOM = function() {
      const parseThumbnailElements = function() {
        const thumbElements = document.querySelectorAll('.enable-photoswipe')

        const numNodes = thumbElements.length

        const items = []

        let itemEl

        let figureEl

        let size

        let item

        for (let i = 0; i < numNodes; i++) {
          itemEl = thumbElements[i]

          // include only element nodes
          if (itemEl.nodeType !== 1) {
            continue
          }

          figureEl = itemEl.parentElement.parentElement

          size = itemEl.getAttribute('data-dimensions').split('x')

          // create slide object
          item = {
            src: itemEl.getAttribute('data-full'),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10)
          }

          if (figureEl.children.length > 1) {
            item.title = figureEl.children[1].innerHTML
          }

          item.msrc = itemEl.getAttribute('src')

          if (!item.msrc) {
            item.msrc = itemEl.getAttribute('data-src')
          }

          item.el = itemEl // save link to element for getThumbBoundsFn
          items.push(item)
        }

        return items
      }

      // find nearest parent element
      const closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn))
      }

      // triggers when user clicks on thumbnail
      const onThumbnailsClick = function(e) {
        e = e || window.event
        e.preventDefault ? e.preventDefault() : (e.returnValue = false)

        const eTarget = e.target || e.srcElement
        const module = eTarget.closest('.module')
        if (module) {
          self.$refs.bg.style.backgroundColor = module.style.backgroundColor
        }

        // find root element of slide
        const clickedListItem = closest(eTarget, function(el) {
          return el.tagName && el.tagName.toUpperCase() === 'FIGURE'
        })

        if (!clickedListItem) {
          return
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        const childNodes = document.querySelectorAll('.enable-photoswipe')

        const numChildNodes = childNodes.length

        let nodeIndex = 0

        let index

        for (let i = 0; i < numChildNodes; i++) {
          if (childNodes[i].parentElement.parentElement === clickedListItem) {
            index = nodeIndex
            break
          }
          nodeIndex++
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index)
        }
        return false
      }

      const openPhotoSwipe = function(index) {
        const pswpElement = document.querySelectorAll('.pswp')[0]

        const items = parseThumbnailElements()

        // define options (if needed)
        // options = {

        //   preload: [1, 1],
        //   history: false,
        //   closeEl: true,
        //   captionEl: true,
        //   fullscreenEl: true,
        //   zoomEl: true,
        //   shareEl: false,
        //   counterEl: false,
        //   arrowEl: true,
        //   preloaderEl: true,
        //   spacing: 1,

        //   getThumbBoundsFn: function (index) {
        //     // See Options -> getThumbBoundsFn section of documentation for more info

        //     let thumbnail = items[index].el
        //     // find thumbnail

        //     let pageYScroll = window.pageYOffset || document.documentElement.scrollTop

        //     let rect = thumbnail.getBoundingClientRect()

        //     return {x: rect.left, y: rect.top + pageYScroll, w: rect.width}
        //   }

        // }
        const options = {
          preload: [1, 1],
          history: false,
          closeEl: true,
          captionEl: true,
          fullscreenEl: true,
          zoomEl: true,
          shareEl: false,
          counterEl: false,
          arrowEl: true,
          preloaderEl: true,
          spacing: 1,
          showHideOpacity: true,
          getThumbBoundsFn: false
        }

        options.index = parseInt(index, 10)

        const gallery = new PhotoSwipe(
          pswpElement,
          PhotoSwipeUIDefault,
          items,
          options
        )

        gallery.listen('afterChange', function(index, item) {
          const module = this.currItem.el.closest('.module')
          if (module) {
            self.$refs.bg.style.backgroundColor = module.style.backgroundColor
          }
        })
        gallery.init()
      }

      const thumbElements = document.querySelectorAll('.enable-photoswipe')
      for (let i = 0, l = thumbElements.length; i < l; i++) {
        thumbElements[i].onclick = onThumbnailsClick
      }
    }

    bus.$on('toggleLoading', (status = true) => {
      setTimeout(function() {
        initPhotoSwipeFromDOM()
      }, 1000)
    })
  }
}
</script>

<style scoped lang="scss">
@import '../node_modules/photoswipe/src/css/main-settings';

.pswp {
  z-index: 10000;
}

.pswp__bg {
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 300ms;
}

.pswp__container {
  //transition: all 500ms $easeOutQuart;
}
::v-deep {
  .pswp__caption.pswp__caption--fake {
    display: none;
  }
  .pswp__caption__center {
    p {
      display: inline-block;
    }
    .description {
      margin-left: 20px;
      opacity: 0.5;
    }
  }
  .pswp__zoom-wrap {
    .video {
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
    }
  }
}

/*

		1. Buttons

  */

/* <button> css reset */
.pswp__button {
  width: 44px;
  height: 44px;
  position: relative;
  background: none;
  cursor: pointer;
  overflow: visible;
  -webkit-appearance: none;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
  opacity: 1;
  transition: opacity 0.2s;
  box-shadow: none;

  &:focus,
  &:hover {
    opacity: 1;
  }

  &:active {
    outline: none;
    opacity: 0.9;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

/* pswp__ui--over-close class it added when mouse is over element that should close gallery */
.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button {
  margin: 35px 5px;
}

.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background: url(#{$icons-path}arrow.svg) 0 0 no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}

.pswp__button--close {
  background-image: url(#{$icons-path}close.svg);
  margin-right: 55px;
}

.pswp__button--share {
  background-position: -44px -44px;
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
  background-image: url(#{$icons-path}fullscreen.svg);
}

.pswp__button--zoom {
  display: none;
  background-position: -88px 0;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

/* no arrows on touch screens */
.pswp--touch {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    visibility: hidden;
  }
}

/*
		Arrow buttons hit area
		(icon is added to :before pseudo-element)
	*/
.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background: none;
  top: 50%;
  margin-top: -50px;
  width: 70px;
  height: 100px;
  position: absolute;
}
.pswp__button--arrow--left:after,
.pswp__button--arrow--right:after {
  content: '';
  width: 44px;
  height: 44px;
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  content: '';
  top: 35px;
  height: 30px;
  width: 32px;
  position: absolute;
  z-index: 2;
}

.pswp__button--arrow--left:before {
  left: 55px;
  background-position: center;
  transform: rotate(180deg);
}

.pswp__button--arrow--right:before {
  right: 55px;
  background-position: center;
}
.pswp__button--arrow--left:after {
  left: 49px;
  top: 28px;
}

.pswp__button--arrow--right:after {
  right: 49px;
  top: 28px;
}

.pswp__button.pswp__button--fs {
  display: none;
}

/*

		2. Share modal/popup and links

    */

.pswp__counter,
.pswp__share-modal {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  position: absolute;
  z-index: $pswp__root-z-index + 100;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: $pswp__root-z-index + 120;
  position: absolute;
  background: #fff;
  top: 56px;
  border-radius: 2px;
  display: block;
  width: auto;
  right: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  transform: translateY(6px);
  transition: transform 0.25s;
  -webkit-backface-visibility: hidden;
  will-change: transform;

  a {
    display: block;
    padding: 8px 12px;
    color: $black;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      text-decoration: none;
      color: $black;
    }

    &:first-child {
      /* round corners on the first/last list item */
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      border-radius: 0 0 2px 2px;
    }
  }
}

.pswp__share-modal--fade-in {
  opacity: 1;

  .pswp__share-tooltip {
    transform: translateY(0);
  }
}

/* increase size of share links on touch devices */
.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook {
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -12px;
    right: 15px;
    border: 6px solid rgba(0, 0, 0, 0);
    border-bottom-color: #fff;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    pointer-events: none;
  }

  &:hover {
    background: #3e5c9a;
    color: #fff;

    &:before {
      border-bottom-color: #3e5c9a;
    }
  }
}

a.pswp__share--twitter {
  &:hover {
    background: #55acee;
    color: #fff;
  }
}

a.pswp__share--pinterest {
  &:hover {
    background: #ccc;
    color: #ce272d;
  }
}

a.pswp__share--download {
  &:hover {
    background: #ddd;
  }
}

/*

		3. Index indicator ("1 of X" counter)

  */

.pswp__counter {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  font-size: 13px;
  line-height: 44px;
  color: #fff;
  opacity: 0.75;
  padding: 0 10px;
}

/*

		4. Caption

  */

.pswp__caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 85px;
  transition: opacity 0.2;

  .pswp--zoomed-in & {
    opacity: 0;
  }

  ::v-deep {
    .caption {
      margin-top: 0;
      display: block;
    }
    .credits {
      margin-top: 5px;
      opacity: 0.5;
      display: block;
    }
  }
}

.pswp__caption__center {
  text-align: center;
  max-width: 620px;
  margin: 0 auto;
  font-size: 15px;
  padding: 10px;
  line-height: 20px;
  color: $black;
}

.pswp__caption--empty {
  display: none;
}

/* Fake caption element, used to calculate height of next/prev image */
.pswp__caption--fake {
  visibility: hidden;
}

/*

		5. Loading indicator (preloader)

		You can play with it here - http://codepen.io/dimsemenov/pen/yyBWoR

  */

.pswp__preloader {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -22px;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  will-change: opacity;
  direction: ltr;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;

  .pswp__preloader__icn {
    /* We use .gif in browsers that don't support CSS animation */
    // background: url(#{$icons-path}preloader.gif) 0 0 no-repeat;
  }
}

.pswp--css_animation {
  .pswp__preloader--active {
    opacity: 1;

    .pswp__preloader__icn {
      animation: clockwise 500ms linear infinite;
    }

    .pswp__preloader__donut {
      animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
    }
  }

  .pswp__preloader__icn {
    background: none;
    opacity: 0.75;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 15px;
    top: 15px;
    margin: 0;
  }

  .pswp__preloader__cut {
    /*
				The idea of animating inner circle is based on Polymer ("material") loading indicator
				by Keanu Lee https://blog.keanulee.com/2014/10/20/the-tale-of-three-spinners.html
      */
    position: relative;
    width: 7px;
    height: 14px;
    overflow: hidden;
  }

  .pswp__preloader__donut {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    float: right;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-140deg);
  }
  100% {
    transform: rotate(0);
  }
}

/*

		6. Additional styles

  */

/* root element of UI */
.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: $pswp__root-z-index + 50;
}

/* top black bar with buttons and "1 of X" indicator */
.pswp__top-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 44px;
  width: 100%;
}

.pswp__caption,
.pswp__top-bar,
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity $pswp__controls-transition-duration
    cubic-bezier(0.4, 0, 0.22, 1);
}

/* pswp--has_mouse class is added only when two subsequent mousemove events occur */
.pswp--has_mouse {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    visibility: visible;
  }
}

/* pswp__ui--idle class is added when mouse isn't moving for several seconds (JS option timeToIdle) */

.pswp__ui--idle {
  .pswp__top-bar {
    opacity: 0;
  }

  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    opacity: 0;
  }
}

/*
		pswp__ui--hidden class is added when controls are hidden
		e.g. when user taps to toggle visibility of controls
	*/
.pswp__ui--hidden {
  .pswp__top-bar,
  .pswp__caption,
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    /* Force paint & create composition layer for controls. */
    opacity: 0.001;
  }
}

/* pswp__ui--one-slide class is added when there is just one item in gallery */
.pswp__ui--one-slide {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right,
  .pswp__counter {
    display: none;
  }
}

.pswp__element--disabled {
  display: none !important;
}

@if $pswp__include-minimal-style == true {
  .pswp--minimal--dark {
    .pswp__top-bar {
      background: none;
    }
  }
}
</style>

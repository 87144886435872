var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'touch': _vm.$store.state.isTouchDevice,
      'safari': _vm.$store.state.isSafari,
      'header-hidden': _vm.headerHidden,
      'menu-hover': _vm.menuHover
    },
    'node-' + _vm.$store.state.pageID,
    _vm.$store.state.pageType,
    _vm.isSiteLoaded,
    { loading: _vm.loadingOn }
  ],style:([_vm.maxWidth]),attrs:{"id":"app"}},[_c('Header',{on:{"pin":_vm.pin,"unpin":_vm.unpin,"mouse-over":_vm.mouseOver,"mouse-out":_vm.mouseOut}}),_vm._v(" "),_c('div',{staticClass:"page-container"},[_c('transition',{attrs:{"name":"page","mode":"out-in"},on:{"leave":_vm.beforeLeave,"afterLeave":_vm.afterLeave,"afterEnter":_vm.afterEnter}},[_c('nuxt',{key:_vm.$route.path,attrs:{"nuxt-child-key":_vm.routerViewKey}})],1)],1),_vm._v(" "),_c('Footer',{attrs:{"changing-page":_vm.changingPage}}),_vm._v(" "),_c('no-ssr',[_c('Photoswipe')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
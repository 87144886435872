import Vue from 'vue'

/**
 * A pretty barren Vue component responsible for holding the endpoint
 * as well as carrying events between components.
 */
export default new Vue({
  data() {
    return {
      /* eslint-disable-next-line no-undef */
      REST_ENDPOINT: 'https://wp.andtradition.com/wp-json'
    }
  },

  methods: {
    updateEndpoint: function(endpoint) {
      this.REST_ENDPOINT = endpoint
      this.$emit('clearErrors')
      this.$emit('bumpViewKey', 'Updating content...')
    }
  }
})

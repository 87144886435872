var url = require('url')

export default {
  methods: {
    getQueryString(params) {
      return Object
      .keys(params)
      .map(k => {
          if (Array.isArray(params[k])) {
              return params[k]
                  .map(val => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
                  .join('&')
          }
  
          return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
      })
      .join('&')
    },
    errorLog(error) {
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error);
        console.error('Request error');
        console.error("Error", error.message);
      } else {
        console.error("Error", error.message);
      }
    },
    /**
     * Get response from memory, if it exists. Otherwise, AJAX.
     *
     * @param  {string} path The endpoint
     * @return {Promise}
     */
    get: function(path, params) {
      return new Promise(async (resolve, reject) => {
        const parsed = JSON.stringify(params)
        let timeout = 5000
        if (params && params.search) {
          timeout = 30000
        }

        let newPath = path
        if (parsed) {
          newPath = path + parsed
        }
        if (params) {
          params = this.getQueryString(params)

        }
        
        if (this.$store.state.cache.requests[newPath] !== undefined) {
          resolve(this.$store.state.cache.requests[newPath])
        } else {
          fetch(
            this.$store.state.endpoint + path + (params ? '?'+params : '')
          )
          .then(response => response.json())
            .then(response => {
            response = {data: response}
            this.$store.commit('cache/saveRequest', {
              newPath,
              data: response
            })

            // -- Generate mock requests for later use on individual posts.
            // -- Mimics structure of response if it were authentic.
            if (path.startsWith('/trouble/post') && !path.includes('slug')) {
              response.data.forEach(post => {
                this.$store.commit('cache/saveRequest', {
                  path: `/api/trouble/post?slug=${post.slug}`,
                  data: {
                    data: [post]
                  }
                })
              })
            }
            resolve(response)
          })
          .catch(error => console.error('Error:', error));
          // .then((response) => {

          //   this.$store.commit('cache/saveRequest', {
          //     newPath,
          //     data: response
          //   })

          //   // -- Generate mock requests for later use on individual posts.
          //   // -- Mimics structure of response if it were authentic.
          //   if (path.startsWith('/trouble/post') && !path.includes('slug')) {
          //     response.data.forEach(post => {
          //       this.$store.commit('cache/saveRequest', {
          //         path: `/api/trouble/post?slug=${post.slug}`,
          //         data: {
          //           data: [post]
          //         }
          //       })
          //     })
          //   }
          //   resolve(response)
          // })
          // .catch((error) => {
          //   this.errorLog(error)
          // })
        }
      })
    }
  }
}

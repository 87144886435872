export default function(to, from, savedPosition) {
  const nuxt = window['$nuxt']; // in case you don't use the default $nuxt

  let position = { // default, mimics the top position on navigation
    x: 0,
    y: 0,
  };

  if (savedPosition) {
    position = savedPosition;
  }

  return new Promise((resolve) => {
    nuxt.$root.$once('page-after-leave', () => {
      nuxt.$nextTick(() => {
        setTimeout(() => {
          resolve(position);
        }, 50)
      });
    });
  });
}
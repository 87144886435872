export const state = () => ({
  requests: {}
})

export const mutations = {
  saveRequest(state, payload) {
    // -- If the request max has been hit, remove the first one.
    if (Object.keys(state.requests).length >= 150) {
      delete state.requests[Object.keys(state.requests)[0]]
    }

    state.requests[payload.newPath] = payload.data
  },

  wipe(state) {
    state.requests = {}
  }
}

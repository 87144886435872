<template>
  <div
    v-vpshow
    :class="[
      data.post_type,
      layout === 'list' && imageRight ? 'image-right' : ''
    ]"
    class="teaser"
  >
    <router-link ref="anchor" :to="{ path: getUrl(data.url), params: {} }">
      <template v-if="fields">
        <template v-for="(field, index) in fields">
          <div
            v-if="field.acf_fc_layout === 'image' && layout === 'list'"
            :key="index"
            ref="list-image"
            v-lazy:background-image="getImage(field.image_crop) ? getCDN(getImage(field.image_crop)[0]) : null"
            class="image"
            :style="[layout === 'list' ? imageLayout : null, {paddingBottom: getAspectRatio(field.image_crop) * 100 + '%'}]"
          >
          </div>
        </template>
        <div class="fields" :style="layout === 'list' ? textLayout : null">
          <div class="fields-inner">
            <template v-for="(field, index) in fields">
              <div
                v-if="
                  field.show_element &&
                    field.acf_fc_layout === 'image' &&
                    layout !== 'list'
                "
                :key="index"
                class="image"
                :class="getImage(field.image_crop) && getImage(field.image_crop).secondary && !$store.state.isTouchDevice ? 'has-secondary' : null"
                :layout="{paddingBottom: getAspectRatio(field.image_crop) * 100 + '%'}"
                :style="[layout === 'list' ? imageLayout : null, {paddingBottom: getAspectRatio(field.image_crop) * 100 + '%'}]"
              >
                <img
                  v-lazy="getImage(field.image_crop) ? getCDN(getImage(field.image_crop)[0]) : null"
                  :style="{paddingBottom: getAspectRatio(field.image_crop) * 100 + '%'}"
                  :alt="data.post_title"
                />
                <img
                  v-if="getImage(field.image_crop) && getImage(field.image_crop).secondary && !$store.state.isTouchDevice"
                  :src="getImage(field.image_crop) ? getCDN(getImage(field.image_crop).secondary) : null"
                  class="secondary-image"
                  :alt="data.post_title"
                />
              </div>

              <div
                v-if="field.show_element && field.acf_fc_layout === 'title'"
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="title"
              >
                <template v-if="data.post_type === 'product'">
                  <div class="post-title">
                    {{ data.title }} <sup>{{ data.superscript }}</sup>
                  </div>
                </template>
                <template v-else>
                  <div class="post-title">{{ data.post_title }}</div>
                </template>
                <template v-if="data.post_type === 'product'">
                  <div class="variants desktop">{{ data.label }}</div>
                </template>
              </div>

              <div
                v-if="field.show_element && field.acf_fc_layout === 'name'"
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="name"
                v-html="data.post_title"
              />

              <div
                v-if="field.show_element && field.acf_fc_layout === 'label'"
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="label"
                v-html="data.topic ? data.topic[0]['post_title'] : null"
              />

              <div
                v-if="
                  field.show_element &&
                    field.acf_fc_layout === 'job_title' &&
                    data.job_title
                "
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="label"
                v-html="data.job_title"
              />

              <div
                v-if="
                  field.show_element &&
                    field.acf_fc_layout === 'excerpt' &&
                    data.excerpt
                "
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="excerpt"
                v-html="data.post_type === 'story' ? data.excerpt + '<div style=text-decoration:underline;margin-top:10px;>Read more</div>' : truncateString(data.excerpt, 145) + '<div>Read more</div>'"
              />

              <div
                v-if="
                  field.show_element &&
                    field.acf_fc_layout === 'person' &&
                    data.person
                "
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="person"
              >
                <div v-html="data.person ? data.person[0].post_title : '&nbsp;'"></div>

                <template v-if="data.post_type === 'product' && data.variants && data.variants > 1">
                  <div class="variants desktop">{{ data.variants }} Variants</div>
                </template>

              </div>

              <ShopPrice
                v-if="
                  field.show_element &&
                    data.shopify_id &&
                    field.acf_fc_layout === 'product_info'
                "
                :key="index"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                :variant-id="data.shopify_id"
                :color="color"
              />
            </template>
            <template v-for="(field, index) in fields">
              <div
                v-if="field.show_element && field.acf_fc_layout === 'title'"
                :key="index + '-mobile'"
                :class="field.font_style ? 'font-' + field.font_style : ''"
                :style="[
                  { color: field.color },
                  { textAlign: field.text_align }
                ]"
                class="title"
              >
                <template v-if="data.post_type === 'product'">
                  <div class="variants mobile">{{ data.label }}</div>
                </template>
                <template v-if="data.post_type === 'product' && data.variants && data.variants > 1">
                  <div class="variants mobile">{{ data.variants }} Variants</div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </router-link>
  </div>
</template>

<script>
import { TweenMax } from 'gsap'
import utils from '@/mixins/utils'

export default {
  name: 'Teaser',

  mixins: [utils],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    layout: {
      type: String,
      default: 'blocks'
    },
    color: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    format: {
      type: String,
      default: 'original'
    }
  },

  data() {
    return {
      lineColor: {
        'background-color': this.color
      },
      rand: 100 + Math.random() * 200
    }
  },

  computed: {
    bodyStyle() {
      return this.$store.getters.bodyStyle
    },
    imageRight() {
      return this.fields.slice(-1)[0].acf_fc_layout === 'image'
    },
    imageLayout() {
      const listdata = this.$parent.$parent.data.list
      if (listdata && listdata.row_layout) {
        const columns = listdata.row_variant.split(',')

        if (this.imageRight) {
          return this.grid(
            parseInt(columns[1]) - 5 + ',' + columns[1],
            parseInt(columns[1]) - 5 + ',' + columns[1],
            '5,6'
          )
        } else {
          return this.grid(
            columns[0] + ',' + (parseInt(columns[0]) + 5),
            columns[0] + ',' + (parseInt(columns[0]) + 5),
            '1,2'
          )
        }
      } else {
        if (this.imageRight) {
          return this.grid('8,10', '8,10', '5,6')
        } else {
          return this.grid('3,5', '3,5', '1,2')
        }
      }
    },
    textLayout() {
      const listdata = this.$parent.$parent.data.list
      if (listdata && listdata.row_layout) {
        const columns = listdata.row_variant.split(',')

        if (this.imageRight) {
          return this.grid(
            columns[0] + ',' + parseInt(columns[1] - 6),
            columns[0] + ',' + parseInt(columns[1] - 6),
            '1,4'
          )
        } else {
          return this.grid(
            parseInt(columns[0]) + 6 + ',' + columns[1],
            parseInt(columns[0]) + 6 + ',' + columns[1],
            '3,6'
          )
        }
      } else {
        if (this.imageRight) {
          return this.grid('3,7', '3,7', '1,4')
        } else {
          return this.grid('6,10', '6,10', '3,6')
        }
      }
    }
  },
  mounted() {
    // if (this.layout === 'list') {
    //   const offset = this.$el.offsetTop
    //   const height = this.$refs['list-image'][0].offsetHeight
    //   const parentHeight =
    //     this.$el.parentElement.offsetHeight - this.$el.offsetHeight

    //   if (height * 0.5 > offset) {
    //     const amount = (offset / height) * 100
    //     this.$refs['list-image'][0].style.transform =
    //       'translateY(calc(-' + amount + '% - 18px))'
    //   }
    //   if (height * 0.5 > parentHeight - offset) {
    //     const amount = 100 - ((parentHeight - offset) / height) * 100
    //     this.$refs['list-image'][0].style.transform =
    //       'translateY(calc(-' + amount + '% + 18px))'
    //   }
    // }
    // if (this.layout !== 'list') {
    //   window.addEventListener('scroll', this.handleScroll)
    // }
  },
  destroyed() {
    // if (this.layout !== 'list') {
    //   window.removeEventListener('scroll', this.handleScroll)
    // }
  },
  methods: {
    handleScroll(e) {
      const wh = window.innerWidth
      const bounds = this.$refs.anchor.$el.getBoundingClientRect()
      if (bounds.top > wh / 2 && bounds.top < wh) {
        TweenMax.to(this.$refs.anchor.$el, 0.2, {
          y: (bounds.top / wh - 0.5) * this.rand
        })
      }
    },
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    getImage(crop) {
      if(this.data.image && this.data.image.includes('.gif')) {
        this.data.image_teaser[0] = this.data.image_teaser[0].replace('-640x800', '').replace('-600x800', '').replace('-533x800', '')
      }
      switch (crop) {
        case '1to1':
          return this.data.image_teaser_1_1
        case '3to2':
          return this.data.image_teaser_3_2
        case '4to3':
          return this.data.image_teaser_4_3
        case '3to4':
          return this.data.image_teaser_3_4
        case '16to9':
          return this.data.image_teaser_16_9
        default:
          return this.data.image_teaser
      }
    },
    getPreloadImage(crop) {
      switch (crop) {
        case '1to1':
          return this.data.image_preload_1_1
        case '3to2':
          return this.data.image_preload_3_2
        case '4to3':
          return this.data.image_preload_4_3
        case '3to4':
          return this.data.image_preload_3_4
        case '16to9':
          return this.data.image_preload_16_9
        default:
          return this.data.image_preload
      }
    },
    getAspectRatio(crop) {
      switch (crop) {
        case '1to1':
          return this.data.image_teaser_1_1
            ? this.data.image_teaser_1_1[2] / this.data.image_teaser_1_1[1]
            : 1
        case '3to2':
          return this.data.image_teaser_3_2
            ? this.data.image_teaser_3_2[2] / this.data.image_teaser_3_2[1]
            : 0.666
        case '4to3':
          return this.data.image_teaser_4_3
            ? this.data.image_teaser_4_3[2] / this.data.image_teaser_4_3[1]
            : 0.75
        case '3to4':
          return this.data.image_teaser_3_4
            ? this.data.image_teaser_3_4[2] / this.data.image_teaser_3_4[1]
            : 1.333
        case '16to9':
          return this.data.image_teaser_16_9
            ? this.data.image_teaser_16_9[2] / this.data.image_teaser_16_9[1]
            : 0.5625
        default:
          return this.data.image_teaser
            ? this.data.image_teaser[2] / this.data.image_teaser[1]
            : 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.teaser {
  @include fontsize(20);

  a {
    display: block;
    width: 100%;
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }

  .post-type {
    display: none;
    text-transform: capitalize;
    @include fontsize(15);
    opacity: 0.25;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .image {
    overflow: hidden;
    position: relative;
    line-height: 0;
    @include fluid-prop(margin-bottom, 10px, 20px);
    background-color: $white;

    &.has-secondary {
      img:first-child {
        transition: opacity 200ms;
      }
    }

    .tns-inner & {
      background-position: center;
      background-size: cover;
    }
  }

  .secondary-image {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
    transition: opacity 200ms;
  }

  &:hover {
    .image.has-secondary {
      img:first-child {
        opacity: 0;
      }
      .secondary-image {
        opacity: 1;
      }
    }
  }

  img {
    opacity: 0;

    &[lazy='loaded'] {
      opacity: 1;
    }
  }

  &.span-6,
  .span-6 & {
    @include fontsize(25);

    .job-title,
    .price {
      @include fontsize(20);
    }
  }

  &.span-4,
  .span-4 & {
    @include fontsize(20);

    .job-title,
    .price {
      @include fontsize(15);
    }
  }

  .list & {
    @include fluid-prop(margin-bottom, 20px, 40px);
    > a {
      @include grid-container-responsive;
      position: relative;
    }
    .image {
      grid-row-start: 1;
      margin-bottom: 0;
      position: absolute;
      opacity: 0;
      width: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 300ms $easeOutQuart;
      background-blend-mode: multiply;
      background-color: var(--main-color);
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      @include media($mobile) {
        display: none;
      }
    }
    &:hover {
      .image {
        opacity: 1;
      }
    }
    .fields {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .excerpt {
        @include fluid-prop(margin-top, 10px, 15px);
      }
      @include media($mobile) {
        grid-column: 1 col-start / span 6 !important;
      }
    }
  }
}
.link {
  font-weight: bold;
  @include underline-link;
  &:after {
    bottom: 2px;
  }
}
.person,
.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include media($mobile) {
    display: block;
    .six_col &,
    .four_col & {
      .variants.desktop {
        display: none;
      }
      .variants.mobile {
        display: block;
      }
    }
  }
}
.person {
  align-items: flex-start;
}
.variants {
  opacity: 0.5;
  font-weight: normal;

  &.mobile {
    display: none;
  }
}
img {
  line-height: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 500ms $easeOutQuart;
  backface-visibility: initial;
}
.excerpt ::v-deep {
  div {
    opacity: 0.5;
  }
}
</style>

<template>
  <div id="search" v-scroll-lock="open" class="search">
    <div class="wrapper">
      <form>
        <input
          id="search"
          ref="input"
          v-model="text"
          v-debounce:500ms="fetch"
          placeholder="Search"
          type="search"
          @keydown="$event.keyCode === 13 ? enter($event) : false"
        >
        <label for="search" style="opacity:0;">Search</label>
      </form>
      <div v-show="numResults === 0 && text.length > 1 && searchPerformed" class="error">
        <div>No results</div>
        <div class="message">
          <div>Looking for our products?</div>
          <nuxt-link to="/products">Find them here</nuxt-link>
        </div>
      </div>
      <div v-for="(type, key) in types" :key="type" class="result" >
        <div v-if="results[key].length > 0" class="title"><span>{{ type }}</span><span v-if="results[key].length > minResults && show[key] === minResults" class="show-all"><span class="inner">{{ results[key].length }} results</span></span><span v-if="results[key].length > minResults && show[key] === maxResults" class="show-all"><span class="inner" @click="hideAll(key)">Hide</span></span></div>
        <div v-if="results[key].length > 0" class="result-list block-grid four_col">
          <Teaser
            v-for="result in results[key]"
            :key="result.ID"
            :data="result"
            :fields="fields[key]"
            class="span-3"
            :layout="'blocks'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from '@/mixins/ajax'
import utils from '@/mixins/utils'
import Teaser from '@/components/Teaser'

export default {
  name: 'Search',

  components: { Teaser },

  mixins: [ajax, utils],

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route(to, from) {
      this.checkQuery()
    },
  },

  data() {
    return {
      text: '',
      searchPerformed: false,
      numResults: 0,
      minResults: 4,
      maxResults: 100,
      results: {
        product: [],
        story: [],
        person: []
      },
      types: {
        product: 'Products',
        story: 'Journal',
        person: 'Designers'
      },
      show: {
        product: this.minResults,
        story: this.minResults,
        person: this.minResults
      },
      fields: {
        product: [
          {
            acf_fc_layout: 'image',
            image_crop: '3to4',
            show_element: true
          },
          {
            acf_fc_layout: 'title',
            color: '',
            font_style: '5d6fbf7172dbf',
            show_element: true,
            text_align: 'left'
          },
          {
            acf_fc_layout: 'person',
            color: '',
            font_style: '5d6fbff32af45',
            show_element: true,
            text_align: 'left'
          }
        ],
        story: [
          {
            acf_fc_layout: 'image',
            image_crop: 'default',
            show_element: true
          },
          {
            acf_fc_layout: 'title',
            color: '',
            font_style: '5dcbc4b854e98',
            show_element: true,
            text_align: 'left'
          },
          {
            acf_fc_layout: 'excerpt',
            color: '',
            font_style: '5d6e50b4bca21',
            show_element: true,
            text_align: 'left'
          }
        ],
        person: [
          {
            acf_fc_layout: 'image',
            image_crop: '3to4',
            show_element: true
          },
          {
            acf_fc_layout: 'title',
            color: '',
            font_style: '5dcbc4b854e98',
            show_element: true,
            text_align: 'left'
          }
        ]
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.checkQuery()
    }, 500);
  },

  methods: {
    enter(event) {
      event.preventDefault()
      this.fetch()
      this.$refs.input.blur()
    },
    showAll(key) {
      this.show[key] = this.maxResults
    },
    hideAll(key) {
      this.show[key] = this.minResults
    },
    focus() {
      this.text = ''
      this.fetch()
      this.$refs.input.focus()
    },
    fetch() {
      if (this.$nuxt && this.$nuxt.$loading && this.$nuxt.$loading.start) {
        this.$nuxt.$loading.start()
      }
      const self = this
      if (this.text.length > 1) {
        const params = {
          search: this.text
        }
        this.get(`/trouble/search`, params).then(response => {
          Object.keys(self.results).forEach(function(key) {
            self.results[key] = []
          })
          this.numResults = response.data.length
          this.searchPerformed = true
          response.data.forEach(function(item) {
            if (self.results[item.post_type]) {
              self.results[item.post_type].push(item)
            }
          })
          if (this.$nuxt) {
            this.$nuxt.$loading.finish()
          }
          this.$router.push({
            path: this.$route.path,
            query: params
          })
        })
      } else {
        Object.keys(self.results).forEach(function(key) {
          self.results[key] = []
        })
        if (this.$nuxt) {
          this.$nuxt.$loading.finish()
        }
        this.searchPerformed = false
      }
      Object.keys(self.show).forEach(function(key) {
        self.show[key] = self.minResults
      })
    },
    alterData(result) {
      if (result.better_featured_image) {
        result.image =
          result.better_featured_image.media_details.sizes.teaser.source_url
        result.image_teaser = [
          result.better_featured_image.media_details.sizes.teaser.width,
          result.better_featured_image.media_details.sizes.teaser.height
        ]

        result.image_preload =
          result.better_featured_image.media_details.sizes.preload
      }

      result.post_title = result.post_name
      result.post_name = result.link

      return result
    },
    checkQuery() {
      if (this.$route.query.search) {
        this.text = this.$route.query.search;
        this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  @include fluid-prop(padding-top, 80px, 300px);
  background-color: $white;
  overflow-y: scroll;
  text-align: left;
  background-color: $secondary-color;
}
.wrapper {
  @include grid-container-responsive;
  @include fluid-prop(padding-bottom, 60px, 100px);
}
.title {
  @include fontsize(30);
  font-weight: 500;
  @include fluid-prop(padding-top, 30px, 100px);
  @include fluid-prop(padding-bottom, 10px, 30px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .show-all {
    @include fontsize(15);
    transition: opacity 100ms;
  }
}
.result,
form,
.result-list {
  @include grid-item(12, 1);

  @include media($mobile) {
    @include grid-item(6, 1);
  }
}
.error {
  text-align: center;
  @include fontsize(30);
  @include grid-item(12, 1);

  @include media($mobile) {
    @include grid-item(6, 1);
  }

  .message {
    margin-top: 20px;
    @include fontsize(15);
  }

  a {
    text-decoration: underline;
  }
}
input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $black;
  @include fontsize(36);
  font-weight: 500;
  padding: 0;
  padding-bottom: 10px;
  border-radius: 0;
  transition: all 500ms $easeOutExpo 300ms;
  transform: translateY(-20px);
  opacity: 0;

  .open & {
    opacity: 1;
    transform: translateY(0);
  }

  &::placeholder {
    color: $black;
    opacity: 0.25;
  }
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>

import Vue from 'vue'
const VueScrollTo = require('vue-scrollto')

export const state = () => ({
  players: {},
  activePlayer: null,
  isFullscreen: false,
  original: {},
  next: {}
})

export const getters = {
  getNext: state => {
    if (state.next) {
      return state.next
    }
  },
  getOriginal: state => {
    if (state.original) {
      return state.original
    }
  },
  getActivePlayer: state => {
    return state.activePlayer
  },
  getPlayers: state => {
    return state.players
  }
}

export const mutations = {
  registerPlayer(state, payload) {
    Vue.set(state.players, payload.data._uid, payload.data)
  },

  setOriginal(state, payload) {
    state.isFullscreen = true
    Vue.set(state.original, 'source', payload.data.player.embed.element.src)
    Vue.set(state.original, 'type', payload.data.player.type)
    Vue.set(state.original, 'provider', payload.data.player.provider)
  },

  setExitFullscreen(state, payload) {
    state.isFullscreen = false
  },

  unRegisterPlayer(state, payload) {
    // Remove from players object
    Vue.delete(state.players, payload.data._uid)

    // If this is activePlayer, reset state
    if (state.activePlayer === payload.data) {
      state.activePlayer = null
    }
  },
  playerEnded(state, payload) {
    if (state.activePlayer === payload.data) {
      // Get next key
      const keys = Object.keys(state.players)
      const next =
        keys[(keys.indexOf(payload.data._uid.toString()) + 1) % keys.length]
      if (next) {
        // Play next video
        const options = {
          offset: -100,
          onDone: element => {
            if (state.players[next].player) {
              state.players[next].player.play()
            } else {
              state.players[next].loadVimeo(true)
            }
          },
          x: false,
          y: true
        }
        if (next === keys[0]) {
          VueScrollTo.scrollTo(
            document.getElementsByClassName('footer')[0],
            500
          )
        } else {
          VueScrollTo.scrollTo(state.players[next].$el, 500, options)
        }
      }
    }
  },
  setActivePlayer(state, payload) {
    // If an activePlayer has been set before, pause it
    if (state.activePlayer && !state.isFullscreen) {
      if (state.activePlayer !== payload.data) {
        if (state.activePlayer.player.playing) {
          state.activePlayer.player.pause()
        }
      }
    }
    state.activePlayer = payload.data

    if (Object.keys(state.players).length !== 0 && state.activePlayer) {
      const keys = Object.keys(state.players)
      const next =
        // eslint-disable-next-line standard/computed-property-even-spacing
        keys[
          (keys.indexOf(state.activePlayer._uid.toString()) + 1) % keys.length
        ]
      Vue.set(state.next, 'source', state.players[next].playerSrc())
      Vue.set(state.next, 'type', state.players[next].playerType())
      Vue.set(state.next, 'provider', state.players[next].provider())
      Vue.set(state.next, 'element', state.players[next])
    }
  }
}

<template>
  <ul :class="location" v-if="menu">
    <li
      v-for="item in menu.items"
      :key="item.id"
      :class="[item.child_items && item.child_items.length > 0 ? 'has-submenu' : '', item.hide_on_desktop ? 'mobile-only' : null]"
      v-on="item.child_items ? { click, mouseenter, mouseleave } : {}"
    >
      <nuxt-link
        v-if="item.type !== 'custom'"
        :to="getUrl(item.url)"
      >
        <div class="menu-item-label" v-html="item.title"/>
      </nuxt-link>
      <span v-if="item.type === 'custom' && item.url === '#'" v-html="item.title"/>
      <a
        v-if="item.type === 'custom' && item.url !== '#'"
        :href="item.url.length > 1 ? item.url : null"
        :target="item.url.length > 1 ? '_blank' : '_self'"
        :class="location === 'social-menu' ? item.title: null"
        class="external"
        rel="noopener"
      >
        <span class="title" v-html="item.title" />
        <img v-if="item.acf && item.acf.icon" class="icon" :src="item.acf.icon" :alt="item.title + ' icon'">
      </a>
      <ul v-if="item.child_items && item.child_items.length > 0" class="submenu">
        <li
          v-for="subitem in item.child_items"
          :key="subitem.id"
          :class="subitem.hide_on_desktop ? 'mobile-only' : null"
        >
          <nuxt-link
            v-if="subitem.type !== 'custom' || (subitem.type === 'custom' && !subitem.url.includes('http') && subitem.url !== '#' && subitem.url !== '#cookie-settings')"
            :to="getUrl(subitem.url)"
            @click.native="submenuClick"
          >
            <div class="menu-item-label" v-html="subitem.title"/>
          </nuxt-link>
          <span v-if="subitem.type === 'custom' && subitem.url === '#'" v-html="subitem.title"/>
          <a
            v-if="subitem.type === 'custom' && subitem.url === '#cookie-settings'"
            href="#"
            class="cookie-settings"
            rel="noopener"
            @click="changeCookieSettings"
          >
            <div class="menu-item-label" v-html="subitem.title"/>
          </a>
          <a
            v-if="subitem.type === 'custom' && subitem.url !== '#' && subitem.url.includes('http')"
            :href="subitem.url.length > 1 ? subitem.url : null"
            :target="subitem.url.length > 1 ? '_blank' : '_self'"
            class="external"
            rel="noopener"
          >
            <span v-html="subitem.title"/>
          </a>
        </li>
      </ul>
    </li>
    <slot></slot>
  </ul>
</template>

<script>
import utils from '@/mixins/utils'
import ajax from '@/mixins/ajax'
export default {
  name: 'Menu',

  mixins: [utils, ajax],

  props: {
    location: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      menu: null,
      iconStyle: {
        backgroundColor: ''
      }
    }
  },

  computed: {
    currentBaseTextColor() {
      return this.$store.state.styles.baseTextColor
    },
    labelStyle() {
      return this.$store.getters.labelStyle
    }
  },
  watch: {
    currentBaseTextColor(newValue) {
      this.iconStyle.backgroundColor = newValue
      if (this.$el.style) {
        // this.$el.style.setProperty('--underline-color', this.iconStyle.backgroundColor)
      }
    }
  },

  created: async function() {
    this.menu = await this.getMenu()
  },
  mounted() {
    // this.$el.style.setProperty('--underline-color', this.iconStyle.backgroundColor)
  },

  methods: {
    changeCookieSettings(e) {
      e.preventDefault()
      if (window && window.CookieConsent) {
        window.CookieConsent.renew()
      }
    },
    submenuClick(e) {
      this.$emit('mouse-out')
      e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
        'hover'
      )
    },
    mouseenter(e) {
      this.$emit('mouse-over')
      e.currentTarget.classList.add('hover')
    },
    mouseleave(e) {
      this.$emit('mouse-out')
      e.currentTarget.classList.remove('hover')
    },
    click(e) {
      const submenu = e.target.lastChild
      if (submenu.style) {
        if (submenu.style.height !== submenu.scrollHeight + 'px') {
          submenu.style.height = submenu.scrollHeight + 'px'
        } else {
          submenu.style.height = '0px'
        }
      }
    },
    getMenu: async function() {
      try {
        const response = await this.get(
          `/menus/v1/locations/` + this.location
        )
        if (this.location !== 'headerMenu' && response.data.length) {
          this.$store.commit('setSideMenu', true)
        }

        return response.data
      }
      catch(error) {
        this.errorLog(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// All menus
a {
  font-family: inherit;
}
ul.footer-menu {
  .submenu {
    border-top: 1px solid var(--line-color, rgba(0, 0, 0, 0.1));
    padding-top: 10px;
  }
}
.header-menu-left-container,
.header-menu-right-container {
  .router-link-active {
    color: $black;
  }
  ul.header-menu-right,
  ul.header-menu-left {
    > li {
      &.mobile-only {
        display: none;
        @include media($mobile) {
          display: block;
        }
      }
      > a {
        &.nuxt-link-active,
        &:hover {
          color: inherit;
          opacity: 1;
        }
        &.nuxt-link-active {
          opacity: 1;
        }
      }
    }
    ul.submenu {
      position: absolute;
      top: 100%;
      opacity: 0;
      pointer-events: none;
      transition: all 200ms;
      transform: translateY(-10px);
      // background-color: var(--main-color);
      padding-bottom: 10px;

      li {
        margin-top: 10px;
        white-space: nowrap;

        > a {
          &.nuxt-link-exact-active,
          &:hover {
            color: inherit;
            opacity: 1;
          }
          &.nuxt-link-exact-active {
            opacity: 1;
            // @include underline-link(true);
          }
        }
      }
    }

    li {
      display: inline-block;
      margin-right: 30px;
      position: relative;

      .mobile-only {
        display: none;
        @include media($mobile) {
          display: block;
        }
      }

      &.hover {
        .submenu {
          pointer-events: all;
          opacity: 1;
          transform: translateY(0);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: inherit;
        display: block;
        opacity: 0.5;
        transition: opacity 100ms;
      }
    }
  }

  ul.header-menu-right {
    text-align: right;

    .submenu {
      text-align: left;
    }
  }

  ul.side-menu-one,
  ul.side-menu-two {
    text-align: left;
    //--underline-color: black;
    a {
      color: inherit;
      @include underline-link;

      &.router-link-active,
      &:hover {
        color: inherit;
        @include underline-link(true);
        &:after {
          background-color: var(--underline-color);
        }
      }
    }
  }

  ul.submenu {
    li {
      display: block;
    }

    .icon {
      background-color: var(--underline-color);
    }
  }
}

.mobile-menu {
  .header-menu-left {
    border-top: 1px solid $line-color;
    margin-top: 40px;
  }
  > ul {
    text-align: left;
    > li {
      display: block;
      border-bottom: 1px solid $line-color;

      &.has-submenu {
        > a {
          pointer-events: none;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 10px;
            height: 2px;
            background-color: $black;
            transform: rotate(45deg);
            position: absolute;
            right: 27px;
            top: 27px;
          }
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 2px;
            background-color: $black;
            transform: rotate(-45deg);
            position: absolute;
            right: 20px;
            top: 27px;
          }
        }
      }

      > a {
        padding: 20px;
        display: block;
      }
    }
  }
  .submenu {
    height: 0;
    overflow: hidden;
    transition: height 500ms $easeInOutExpo;
    li {
      a {
        opacity: 0.5;
        padding: 10px 20px;
        display: block;
      }
      &:first-child {
        a {
          padding-top: 0;
        }
      }

      &:last-child {
        a {
          padding-bottom: 20px;
        }
      }
    }
  }
}
ul.social-menu {
  .icon {
    width: 16px;
    height: 16px;
    opacity: 0.5;
    transition: opacity 100ms;
    &:hover {
      opacity: 1;
    }
  }
  .title {
    display: none;
  }
}
</style>

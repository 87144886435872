var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"vpshow",rawName:"v-vpshow"}],staticClass:"teaser",class:[
    _vm.data.post_type,
    _vm.layout === 'list' && _vm.imageRight ? 'image-right' : ''
  ]},[_c('router-link',{ref:"anchor",attrs:{"to":{ path: _vm.getUrl(_vm.data.url), params: {} }}},[(_vm.fields)?[_vm._l((_vm.fields),function(field,index){return [(field.acf_fc_layout === 'image' && _vm.layout === 'list')?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.getImage(field.image_crop) ? _vm.getCDN(_vm.getImage(field.image_crop)[0]) : null),expression:"getImage(field.image_crop) ? getCDN(getImage(field.image_crop)[0]) : null",arg:"background-image"}],key:index,ref:"list-image",refInFor:true,staticClass:"image",style:([_vm.layout === 'list' ? _vm.imageLayout : null, {paddingBottom: _vm.getAspectRatio(field.image_crop) * 100 + '%'}])}):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"fields",style:(_vm.layout === 'list' ? _vm.textLayout : null)},[_c('div',{staticClass:"fields-inner"},[_vm._l((_vm.fields),function(field,index){return [(
                field.show_element &&
                  field.acf_fc_layout === 'image' &&
                  _vm.layout !== 'list'
              )?_c('div',{key:index,staticClass:"image",class:_vm.getImage(field.image_crop) && _vm.getImage(field.image_crop).secondary && !_vm.$store.state.isTouchDevice ? 'has-secondary' : null,style:([_vm.layout === 'list' ? _vm.imageLayout : null, {paddingBottom: _vm.getAspectRatio(field.image_crop) * 100 + '%'}]),attrs:{"layout":{paddingBottom: _vm.getAspectRatio(field.image_crop) * 100 + '%'}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getImage(field.image_crop) ? _vm.getCDN(_vm.getImage(field.image_crop)[0]) : null),expression:"getImage(field.image_crop) ? getCDN(getImage(field.image_crop)[0]) : null"}],style:({paddingBottom: _vm.getAspectRatio(field.image_crop) * 100 + '%'}),attrs:{"alt":_vm.data.post_title}}),_vm._v(" "),(_vm.getImage(field.image_crop) && _vm.getImage(field.image_crop).secondary && !_vm.$store.state.isTouchDevice)?_c('img',{staticClass:"secondary-image",attrs:{"src":_vm.getImage(field.image_crop) ? _vm.getCDN(_vm.getImage(field.image_crop).secondary) : null,"alt":_vm.data.post_title}}):_vm._e()]):_vm._e(),_vm._v(" "),(field.show_element && field.acf_fc_layout === 'title')?_c('div',{key:index,staticClass:"title",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ])},[(_vm.data.post_type === 'product')?[_c('div',{staticClass:"post-title"},[_vm._v("\n                  "+_vm._s(_vm.data.title)+" "),_c('sup',[_vm._v(_vm._s(_vm.data.superscript))])])]:[_c('div',{staticClass:"post-title"},[_vm._v(_vm._s(_vm.data.post_title))])],_vm._v(" "),(_vm.data.post_type === 'product')?[_c('div',{staticClass:"variants desktop"},[_vm._v(_vm._s(_vm.data.label))])]:_vm._e()],2):_vm._e(),_vm._v(" "),(field.show_element && field.acf_fc_layout === 'name')?_c('div',{key:index,staticClass:"name",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ]),domProps:{"innerHTML":_vm._s(_vm.data.post_title)}}):_vm._e(),_vm._v(" "),(field.show_element && field.acf_fc_layout === 'label')?_c('div',{key:index,staticClass:"label",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ]),domProps:{"innerHTML":_vm._s(_vm.data.topic ? _vm.data.topic[0]['post_title'] : null)}}):_vm._e(),_vm._v(" "),(
                field.show_element &&
                  field.acf_fc_layout === 'job_title' &&
                  _vm.data.job_title
              )?_c('div',{key:index,staticClass:"label",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ]),domProps:{"innerHTML":_vm._s(_vm.data.job_title)}}):_vm._e(),_vm._v(" "),(
                field.show_element &&
                  field.acf_fc_layout === 'excerpt' &&
                  _vm.data.excerpt
              )?_c('div',{key:index,staticClass:"excerpt",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ]),domProps:{"innerHTML":_vm._s(_vm.data.post_type === 'story' ? _vm.data.excerpt + '<div style=text-decoration:underline;margin-top:10px;>Read more</div>' : _vm.truncateString(_vm.data.excerpt, 145) + '<div>Read more</div>')}}):_vm._e(),_vm._v(" "),(
                field.show_element &&
                  field.acf_fc_layout === 'person' &&
                  _vm.data.person
              )?_c('div',{key:index,staticClass:"person",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.person ? _vm.data.person[0].post_title : '&nbsp;')}}),_vm._v(" "),(_vm.data.post_type === 'product' && _vm.data.variants && _vm.data.variants > 1)?[_c('div',{staticClass:"variants desktop"},[_vm._v(_vm._s(_vm.data.variants)+" Variants")])]:_vm._e()],2):_vm._e(),_vm._v(" "),(
                field.show_element &&
                  _vm.data.shopify_id &&
                  field.acf_fc_layout === 'product_info'
              )?_c('ShopPrice',{key:index,class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ]),attrs:{"variant-id":_vm.data.shopify_id,"color":_vm.color}}):_vm._e()]}),_vm._v(" "),_vm._l((_vm.fields),function(field,index){return [(field.show_element && field.acf_fc_layout === 'title')?_c('div',{key:index + '-mobile',staticClass:"title",class:field.font_style ? 'font-' + field.font_style : '',style:([
                { color: field.color },
                { textAlign: field.text_align }
              ])},[(_vm.data.post_type === 'product')?[_c('div',{staticClass:"variants mobile"},[_vm._v(_vm._s(_vm.data.label))])]:_vm._e(),_vm._v(" "),(_vm.data.post_type === 'product' && _vm.data.variants && _vm.data.variants > 1)?[_c('div',{staticClass:"variants mobile"},[_vm._v(_vm._s(_vm.data.variants)+" Variants")])]:_vm._e()],2):_vm._e()]})],2)])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const state = () => ({
  endpoint: 'https://wp.andtradition.com/wp-json',
  siteOptions: {
    header_defaults: {}
  },
  pageType: '',
  pageID: '',
  contextual_modules: {},
  fontOptions: {
    font_primary: {},
    font_body: {},
    font_headline: {},
    font_image: {
      caption: {},
      credits: {}
    },
    font_labels: {},
    font_quotes: {
      text: {},
      cite: {}
    },
    font_standfirst: {}
  },
  moduleOptions: {},
  imageTop: false,
  isTouchDevice: false,
  isSafari: false,
  coverTop: false,
  styles: {
    backgroundColor: '',
    textColor: '',
    transition: '',
    showNavigation: true,
    baseBackgroundColor: '',
    baseTextColor: '',
    baseFontFamily: '',
    header: {
      backgroundMenu: '',
      backgroundOpen: '',
      backgroundClosed: '',
      textMenu: '',
      textOpen: '',
      textClosed: '',
      hidden: true
    },
    footer: {
      hidden: true
    }
  },
  newSection: false,
  shop: {},
  page: {},
  showProgress: false,
  sections: [],
  sideMenu: false
})
export const mutations = {
  setBaseBackgroundColor(state, data) {
    state.styles.baseBackgroundColor = data
  },
  setBaseTextColor(state, data) {
    state.styles.baseTextColor = data
  },
  setBaseFontFamily(state, data) {
    state.styles.baseFontFamily = data
  },
  setBackgroundColor(state, data) {
    state.styles.backgroundColor = data
  },
  setTextColor(state, data) {
    state.styles.textColor = data
  },
  setShopClient(state, data) {
    state.shop.ui = data
  },
  setPageType(state, data) {
    state.pageType = data
  },
  setPageID(state, data) {
    state.pageID = data
  },
  setTouchDevice(state, data) {
    state.isTouchDevice = data
  },
  setSafari(state, data) {
    state.isSafari = data
  },
  setBackgroundMenu(state, data) {
    state.styles.header.backgroundMenu =
      data ||
      state.siteOptions.header_defaults.header_background_color_menu ||
      state.styles.baseBackgroundColor
  },
  setBackgroundOpen(state, data) {
    state.styles.header.backgroundOpen =
      data ||
      state.siteOptions.header_defaults.header_background_color_open ||
      state.styles.baseBackgroundColor
  },
  setBackgroundClosed(state, data) {
    state.styles.header.backgroundClosed =
      data ||
      state.siteOptions.header_defaults.header_background_color_closed ||
      state.styles.baseBackgroundColor
  },
  setTextMenu(state, data) {
    state.styles.header.textMenu =
      data ||
      state.siteOptions.header_defaults.header_text_color_menu ||
      state.styles.baseTextColor
  },
  setTextOpen(state, data) {
    state.styles.header.textOpen =
      data ||
      state.siteOptions.header_defaults.header_text_color_open ||
      state.styles.baseTextColor
  },
  setTextClosed(state, data) {
    state.styles.header.textClosed =
      data ||
      state.siteOptions.header_defaults.header_text_color_closed ||
      state.styles.baseTextColor
  },
  hideHeader(state, data) {
    state.styles.header.hidden = data
  },
  hideFooter(state, data) {
    state.styles.footer.hidden = data
  },
  showProgress(state, data) {
    state.showProgress = data
  },
  setSections(state, data) {
    state.sections = data
  },
  setSideMenu(state, data) {
    state.sideMenu = data
  },
  setCoverTop(state, data) {
    state.coverTop = data
  },
  siteOptions(state, data) {
    state.siteOptions = data
  },
  fontOptions(state, data) {
    state.fontOptions = data
  },
  moduleOptions(state, data) {
    state.moduleOptions = data
  },
  imageTop(state, data) {
    state.imageTop = data
  },
  page(state, data) {
    state.page = data
  }
}
export const getters = {
  getTextClosed: state => state.styles.header.textClosed,
  getTextOpen: state => state.styles.header.textOpen,
  getTextMenu: state => state.styles.header.textMenu,
  getBackgroundClosed: state => state.styles.header.backgroundClosed,
  getBackgroundOpen: state => state.styles.header.backgroundOpen,
  getBackgroundMenu: state => state.styles.header.backgroundMenu,
  getHideHeader: state => state.styles.header.hidden,
  getHideFooter: state => state.styles.footer.hidden,
  showProgress: state => state.showProgress,
  getSections: state => state.sections,
  getSideMenu: state => state.sideMenu,
  siteOptions: state => state.siteOptions,
  fontOptions: state => state.fontOptions,
  moduleOptions: state => state.moduleOptions,
  imageTop: state => state.imageTop,
  page: state => state.page,
  styles: state => state.styles
}

export default {
  data() {
    return {
      monthsShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      monthsLong: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  },

  methods: {
    handleClicks($event, container) {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that occur inside the component and do not reference external resources
      if (target && target.matches(container + ' a') && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented
        } = $event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        // don't handle same page links/anchors
        const url = new URL(target.href)
        const to = url.pathname + url.search
        if (window.location.pathname !== to && $event.preventDefault) {
          $event.preventDefault()
          this.$router.push(to)
        }
      }
    },
    getFormattedDate: function(time, short) {
      const date = new Date(time)

      if (short) {
        return `${this.monthsShort[date.getMonth()]} ${date.getDate()}`
      } else {
        return `${
          this.monthsShort[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()}`
      }
    },

    getSize(index, total) {
      if (total === 6) {
        return 'span-4'
      }
      let quotient = Math.floor(total / 4)
      const remainder = total % 4

      if (remainder < 2) {
        quotient += -1
      }

      const rest = total - quotient * 4
      let classes = ''

      if (index > rest - 1) {
        classes = 'span-3'

        if ((index - remainder) % 4 === 0) {
          classes += ' first'
        }
      } else {
        if (rest === 2) {
          classes = 'span-6'
        }
        if (rest === 3) {
          classes = 'span-4'
        }
        if (rest === 4) {
          classes = 'span-3'
        }
        if (rest === 5) {
          if (index === 0 || index === 1) {
            classes = 'span-6'
          } else {
            classes = 'span-4'
          }
        }
        if (rest === 6) {
          classes = 'span-4'
        }
        if (rest === 7) {
          if (index === 0 || index === 1 || index === 2) {
            classes = 'span-4'
          } else {
            classes = 'span-3'
          }
        }
      }

      if (index === 0) {
        classes += ' first'
      }
      return classes
    },

    getUrl: function(url) {
      if (url) {
        return url.replace(/^.*\/\/[^/]+/, '')
      } else {
        return false
      }
    },

    getCDN: function(url) {
      if (url) {
        return url
        // return url.replace(
        //   'https://wp.andtradition.com',
        //   'https://330827-1014958-raikfcquaxqncofqfm.stackpathdns.com'
        // )
      } else {
        return false
      }
    },

    shuffle: function(array) {
      let currentIndex = array.length
      let temporaryValue
      let randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    },

    dasherize: function(string) {
      return string
        .replace(/\s+/g, '-')
        .toLowerCase()
        .replace('&', 'and')
        .replace('.', '')
        .replace('/', '-')
    },

    grid: function(columns, columnsMedium, columnsMobile) {
      columns = columns.split(',')
      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        // if (columnsMobile) {
        //   columns = columnsMobile.split(',')
        // }
        // const span = columns[1] - columns[0] + 1
        // switch (true) {
        //   case (span > 6):
        //     columns = [1, 6]
        //     break
        //   case (span <= 6):
        //     if (parseInt(columns[0]) > 7) {
        //       columns = [3, 6]
        //     } else if (parseInt(columns[0]) > 3) {
        //       columns = [2, 5]
        //     } else {
        //       columns = [1, 4]
        //     }
        //     break
        // }
        columns[0] = parseInt(columns[0])
        columns[1] = parseInt(columns[1])
        columns = [columns[0] !== 0 ? 1 : 0, columns[1] !== 13 ? 6 : 13]
      }
      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        if (columnsMobile) {
          columns = columnsMobile.split(',')
        }
      }

      // if (this.$mq === 'medium' || this.$mq === 'small') {
      //   if (columnsMedium) {
      //     columns = columnsMedium.split(',')
      //   } else {
      //     columns[0] = Math.ceil(columns[0] * 0.75)
      //     columns[1] = Math.ceil(columns[1] * 0.75)
      //   }
      // }
      let start = 'col-start ' + columns[0]
      if (columns[0] === 0 || columns[0] === '0') {
        start = 'full'
      }
      const grid = columns[1] - columns[0] + 1
      return {
        gridColumn: start + '/span ' + grid
      }
    },

    percentageGrid: function(columns, columnsMedium, columnsMobile) {
      // const columnsOriginal = columns
      columns = columns.split(',')
      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        if (columnsMobile) {
          columns = columnsMobile.split(',')
        }
        const span = columns[1] - columns[0] + 1
        switch (true) {
          case span > 6:
            columns = [1, 6]
            break
          case span <= 6:
            if (parseInt(columns[0]) > 7) {
              columns = [3, 6]
            } else if (parseInt(columns[0]) > 3) {
              columns = [2, 5]
            } else {
              columns = [1, 4]
            }
            break
        }
      }

      // if (this.$mq === 'medium' || this.$mq === 'small') {
      //   if (columnsMedium && columnsMedium !== columnsOriginal) {
      //     columns = columnsMedium.split(',')
      //   } else {
      //     columns[0] = Math.ceil(columns[0] * 0.75)
      //     columns[1] = Math.ceil(columns[1] * 0.75)
      //   }
      // }

      let column = 8.5
      let left = parseInt(columns[0] - 1)
      let right = parseInt(14 - columns[1] - 2)

      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        column = 26
        left = parseInt(columns[0] - 1)
        right = parseInt(8 - columns[1] - 2)
      }
      if (this.$mq === 'medium' || this.$mq === 'small') {
        // left = parseInt(columns[0] - 1)
        // right = parseInt(11 - columns[1] - 2)
        column = 5.8
      }
      return {
        '--margin-left': left * column + '%',
        '--margin-right': right * column + '%'
      }
    },

    wrappedGrid: function(columns, columnsMedium, columnsMobile, alignRight) {
      // const columnsOriginal = columns
      columns = columns.split(',')
      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        if (columnsMobile) {
          columns = columnsMobile.split(',')
        }
        const span = columns[1] - columns[0] + 1
        switch (true) {
          case span > 6:
            columns = [1, 6]
            break
          case span <= 6:
            if (parseInt(columns[0]) > 7) {
              columns = [3, 6]
            } else if (parseInt(columns[0]) > 3) {
              columns = [2, 5]
            } else {
              columns = [1, 4]
            }
            break
        }
      }

      // if (this.$mq === 'medium' || this.$mq === 'small') {
      //   if (columnsMedium && columnsMedium !== columnsOriginal) {
      //     columns = columnsMedium.split(',')
      //   } else {
      //     columns[0] = Math.ceil(columns[0] * 0.75)
      //     columns[1] = Math.ceil(columns[1] * 0.75)
      //   }
      // }

      let gap = 20
      let column = 6.8
      let left = parseInt(columns[0] - 1)
      let right = parseInt(14 - columns[1] - 2)

      if (this.$mq === 'mobile' || this.$mq === 'tiny') {
        gap = 10
        column = 12
        left = parseInt(columns[0] - 1)
        right = parseInt(8 - columns[1] - 2)
      }
      // if (this.$mq === 'medium' || this.$mq === 'small') {
      //   left = parseInt(columns[0] - 1)
      //   right = parseInt(11 - columns[1] - 2)
      //   gap = 15
      //   column = 9.2
      // }

      return {
        'margin-left': 'calc(' + left * gap + 'px + ' + left * column + '%)',
        'margin-right': 'calc(' + right * gap + 'px + ' + right * column + '%)',
        width:
          'calc(' +
          (columns[1] - columns[0] + 1) * gap +
          'px + ' +
          (columns[1] - columns[0] + 1) * column +
          '% - 60px)'
      }
    },

    hexToRgbA: function(hex, amount) {
      let c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',' +
          amount +
          ')'
        )
      }
    }
  }
}

<template>
  <div
    id="app"
    :class="[
      {
        'touch': $store.state.isTouchDevice,
        'safari': $store.state.isSafari,
        'header-hidden': headerHidden,
        'menu-hover': menuHover
      },
      'node-' + $store.state.pageID,
      $store.state.pageType,
      isSiteLoaded,
      { loading: loadingOn }
    ]"
    :style="[maxWidth]"
  >
    <Header
      @pin="pin"
      @unpin="unpin"
      @mouse-over="mouseOver"
      @mouse-out="mouseOut"
    />
    <div class="page-container">
      <transition
        name="page"
        mode="out-in"
        @leave="beforeLeave"
        @afterLeave="afterLeave"
        @afterEnter="afterEnter"
      >
        <nuxt :key="$route.path" :nuxt-child-key="routerViewKey"/>
      </transition>
    </div>

    <Footer :changing-page="changingPage" />
    <no-ssr>
      <Photoswipe />
    </no-ssr>
  </div>
</template>

<script>
import { TweenMax } from 'gsap'
import bus from '@/components/bus'
import Photoswipe from '@/components/Photoswipe'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import ajax from '@/mixins/ajax'
import { globalAgent } from 'https';
export default {
  components: {
    Photoswipe,
    Header,
    Footer
  },
  mixins: [ajax],

  data() {
    return {
      error: '',
      loadingOn: true,
      loadingMessage: 'Loading content',
      loadingWheel: true,
      showUpdater: false,
      viewKey: 0,
      favicon: '',
      siteLoaded: false,
      authorized: true,
      headerHidden: false,
      menuHover: false,
      sheet: null,
      changingPage: false
    }
  },


  metaInfo() {
    return {
      link: [{ rel: 'shortcut icon', href: this.favicon, type: 'image/png' }]
    }
  },
  computed: {
    buttonStyles() {
      return {
        '--button-color':
          this.$store.state.siteOptions.cookie_btn_text_color || '#000000',
        '--button-background-color':
          this.$store.state.siteOptions.cookie_btn_bg_color || '#ffffff'
      }
    },
    routerViewKey() {
      if (this.$route.matched.length <= 1) {
        return this.$route.fullPath.split(/[?|#]/)[0]
      } else {
        return false
      }
    },
    isSiteLoaded() {
      return this.siteLoaded ? 'site-loaded' : ''
    },
    maxWidth() {
      let columnWidth = 100 // default
      if (this.$store.getters.siteOptions.site_max_width) {
        columnWidth = Math.round(
          (this.$store.getters.siteOptions.site_max_width - 240) / 12 - 20
        )
      }
      return {
        '--column-max-width': columnWidth + 'px'
      }
    }
  },
  created: function() {
    if (process.client) {
      window.GoogleAnalyticsObject = 'ga'
    }

    this.getSite()
    bus.$on('toggleLoading', (status = true) => {
      if (typeof status === 'string') {
        this.loadingOn = true
        this.showWheel = false
        this.loadingMessage = status
        return
      }

      this.loadingMessage = 'Loading...'
      this.loadingWheel = true
      this.loadingOn = status
    })

    bus.$on('bumpViewKey', loadingMessage => {
      bus.$emit('toggleLoading', loadingMessage)
      this.viewKey = this.viewKey + 1
    })

    bus.$on('showUpdater', errorMessage => {
      this.showUpdater = true
      this.error = errorMessage
    })

    bus.$on('clearErrors', () => {
      this.error = ''
    })
  },
  mounted: function() {
    if (process.client) {
      const cylindoScript = document.createElement('script')
      cylindoScript.setAttribute(
        'src',
        'https://viewer.cylindo.com/v4/viewer.min.js'
      )
      document.head.appendChild(cylindoScript)
    }
  },
  methods: {
    isTouchDevice() {
      if (process.client) {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
        const mq = function(query) {
          return window.matchMedia(query).matches
        }
        if ('ontouchstart' in window || window.DocumentTouch) {
          return true
        }
        const query = [
          '(',
          prefixes.join('touch-enabled),('),
          'heartz',
          ')'
        ].join('')
        return mq(query)
      } else {
        return false
      }
    },
    isSafari() {
      if (process.client) {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      } else {
        return false
      }
    },
    pin() {
      this.headerHidden = true
    },
    unpin() {
      this.headerHidden = false
    },
    mouseOver() {
      this.menuHover = true
    },
    mouseOut() {
      this.menuHover = false
    },
    afterLeave() {
      // TweenMax.set(window, { scrollTo: 0 })
      this.$root.$emit('page-after-leave');
      this.menuHover = false
    },
    beforeLeave() {
      this.changingPage = true
    },
    afterEnter() {
      this.changingPage = false
    },
    getSite: async function() {
      const _this = this
      if (process.client) {
        this.sheet = document.createElement('style')
      }

      this.$store.commit('setTouchDevice', this.isTouchDevice())
      this.$store.commit('setSafari', this.isSafari())

      try {
        const response = await this.get(`/acf/v3/options/site_options`)
        this.$store.commit('siteOptions', response.data.acf)
        
        if (response.data.acf.favicon) {
          this.favicon = response.data.acf.favicon.url
        }
        if (response.data.acf.custom_css) {
          this.sheet.innerHTML = response.data.acf.custom_css
        }

        if (response.data.acf) {
          if (response.data.acf.authorization_settings) {
            if (
              response.data.acf.authorization_settings.password_protect &&
              !localStorage.getItem('auth')
            ) {
              this.authorized = false
            }
          }
        }
      } catch(error) {
        this.errorLog(error)
      }

      try {
        const response2 = await this.get(`/acf/v3/options/font_options`)
        this.$store.commit('fontOptions', response2.data.acf)

        if (
          this.$store.state.fontOptions.font_style_image &&
          this.$store.state.fontOptions.font_style_image.caption
        ) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_image.caption,
            '.caption'
          )
        }
        if (
          this.$store.state.fontOptions.font_style_image &&
          this.$store.state.fontOptions.font_style_image.credits
        ) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_image.credits,
            '.credits'
          )
        }

        const styles = [
          ...(this.$store.state.fontOptions.font_styles_display_text
            ? this.$store.state.fontOptions.font_styles_display_text
            : []),
          ...(this.$store.state.fontOptions.font_styles_text
            ? this.$store.state.fontOptions.font_styles_text
            : []),
          ...(this.$store.state.fontOptions.font_styles_quote
            ? this.$store.state.fontOptions.font_styles_quote
            : []),
          ...(this.$store.state.fontOptions.font_styles_promo
            ? this.$store.state.fontOptions.font_styles_promo
            : []),
          ...(this.$store.state.fontOptions.font_styles_product
            ? this.$store.state.fontOptions.font_styles_product
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_label
            ? this.$store.state.fontOptions.font_styles_list_label
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_title
            ? this.$store.state.fontOptions.font_styles_list_title
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_excerpt
            ? this.$store.state.fontOptions.font_styles_list_excerpt
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_product_info
            ? this.$store.state.fontOptions.font_styles_list_product_info
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_job_title
            ? this.$store.state.fontOptions.font_styles_list_job_title
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_name
            ? this.$store.state.fontOptions.font_styles_list_name
            : []),
          ...(this.$store.state.fontOptions.font_styles_list_person
            ? this.$store.state.fontOptions.font_styles_list_person
            : [])
        ]
        const self = this
        styles.forEach(function(s) {
          self.setupFont(s, '.' + 'font-' + s.uid)
        })

        // if (this.$store.state.fontOptions.font_styles_display_text) {
        //   this.setupFont(
        //     this.$store.state.fontOptions.font_styles_display_text[0],
        //     '.display-text'
        //   )
        // }

        // if (this.$store.state.fontOptions.font_styles_text) {
        //   this.setupFont(
        //     this.$store.state.fontOptions.font_styles_text[0],
        //     '.body-text .content'
        //   )
        // }

        if (this.$store.state.fontOptions.font_styles_quote) {
          this.setupFont(
            this.$store.state.fontOptions.font_styles_quote[0],
            '.quote'
          )
        }

        // if (this.$store.state.fontOptions.font_styles_promo) {
        //   this.setupFont(
        //     this.$store.state.fontOptions.font_styles_promo[0],
        //     '.promo, .cover'
        //   )
        // }

        if (this.$store.state.fontOptions.font_style_menu) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.header-menu-left li',
            'main_menu_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.header-menu-right li',
            'main_menu_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.quick-menu li',
            'main_menu_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.filter-bar',
            'main_menu_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.product-top .label',
            'main_menu_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_menu,
            '.footer-menu li',
            'side_menu_one_'
          )
        }
        if (this.$store.state.fontOptions.font_style_button) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_button,
            'a.button',
            'button_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_button,
            'a.simple',
            'simple_'
          )
        }

        if (this.$store.state.fontOptions.font_style_product_price) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_product_price,
            '.product-top .variant-name',
            'desc_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_product_price,
            '.product-top .delivery',
            'desc_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_product_price,
            '#product .price',
            'price_'
          )
        }
        if (this.$store.state.fontOptions.font_style_primary) {
          this.setupFont(
            this.$store.state.fontOptions.font_style_primary,
            '.share',
            'text_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_primary,
            '.breadcrumbs',
            'text_'
          )
          this.setupFont(
            this.$store.state.fontOptions.font_style_primary,
            'body',
            'text_'
          )
        }
        if (process.client) {
          document.body.appendChild(this.sheet)
        }
      } catch(error) {
        this.errorLog(error)
      }
      


      try {
        const response3 = await this.get(`/acf/v3/options/module_options`)
        this.$store.commit('moduleOptions', response3.data.acf)
      } catch(error) {
        this.errorLog(error)
      }
      

      _this.siteLoaded = true
    },

    setupFont(font, classes, prefix = '') {
      if (!process.client) {
        return
      }
      const s = font
      this.sheet.innerHTML += classes + ' {'
      if (s[prefix + 'font']) {
        this.sheet.innerHTML +=
          'font-family:' + s[prefix + 'font'] + ' !important;'
      }
      if (s[prefix + 'letter_spacing']) {
        this.sheet.innerHTML +=
          'letter-spacing:' + s[prefix + 'letter_spacing'] + 'px !important;'
      }
      if (s[prefix + 'style']) {
        this.sheet.innerHTML +=
          'font-style:' + s[prefix + 'style'] + ' !important;'
      }
      if (s[prefix + 'text_decoration']) {
        this.sheet.innerHTML +=
          'text-decoration:' + s[prefix + 'text_decoration'] + ' !important;'
      }
      if (s[prefix + 'text_transform']) {
        this.sheet.innerHTML +=
          'text-transform:' + s[prefix + 'text_transform'] + ' !important;'
      }
      if (s[prefix + 'weight']) {
        this.sheet.innerHTML +=
          'font-weight:' + s[prefix + 'weight'] + ' !important;'
      }
      if (s[prefix + 'size_max']) {
        this.sheet.innerHTML +=
          'font-size: calc(' +
          s[prefix + 'size_min'] +
          'px + ' +
          (s[prefix + 'size_max'] / (s[prefix + 'size_max'] * 0 + 1) -
            s[prefix + 'size_min'] / (s[prefix + 'size_min'] * 0 + 1)) +
          ' * ( (100vw - 400px) / 800)) !important;'
      }
      if (s[prefix + 'line_height_max']) {
        this.sheet.innerHTML +=
          'line-height: calc(' +
          s[prefix + 'line_height_min'] +
          'px + ' +
          (s[prefix + 'line_height_max'] /
            (s[prefix + 'line_height_max'] * 0 + 1) -
            s[prefix + 'line_height_min'] /
              (s[prefix + 'line_height_min'] * 0 + 1)) +
          ' * ( (100vw - 400px) / 800)) !important;'
      }
      if (s[prefix + 'margin_top_max']) {
        this.sheet.innerHTML +=
          'margin-top: calc(' +
          s[prefix + 'margin_top_min'] +
          'px + ' +
          (s[prefix + 'margin_top_max'] /
            (s[prefix + 'margin_top_max'] * 0 + 1) -
            s[prefix + 'margin_top_min'] /
              (s[prefix + 'margin_top_min'] * 0 + 1)) +
          ' * ( (100vw - 400px) / 800)) !important;'
      }
      if (s[prefix + 'margin_bottom_max']) {
        this.sheet.innerHTML +=
          'margin-bottom: calc(' +
          s[prefix + 'margin_bottom_min'] +
          'px + ' +
          (s[prefix + 'margin_bottom_max'] /
            (s[prefix + 'margin_bottom_max'] * 0 + 1) -
            s[prefix + 'margin_bottom_min'] /
              (s[prefix + 'margin_bottom_min'] * 0 + 1)) +
          ' * ( (100vw - 400px) / 800)) !important;'
      }
      this.sheet.innerHTML += '}'
      if (s[prefix + 'size_max']) {
        this.sheet.innerHTML +=
          '@media screen and (max-width: 400px) {' +
          classes +
          ' {font-size: ' +
          s[prefix + 'size_min'] +
          'px !important;}}'
        this.sheet.innerHTML +=
          '@media screen and (min-width: 1200px){' +
          classes +
          ' {font-size: ' +
          s[prefix + 'size_max'] +
          'px !important;}}'
      }
      if (s[prefix + 'line_height_max']) {
        this.sheet.innerHTML +=
          '@media screen and (max-width: 400px) {' +
          classes +
          ' {line-height: ' +
          s[prefix + 'line_height_min'] +
          'px !important;}}'
        this.sheet.innerHTML +=
          '@media screen and (min-width: 1200px){' +
          classes +
          ' {line-height: ' +
          s[prefix + 'line_height_max'] +
          'px !important;}}'
      }
      if (s[prefix + 'margin_top_max']) {
        this.sheet.innerHTML +=
          '@media screen and (max-width: 400px) {' +
          classes +
          ' {margin-top: ' +
          s[prefix + 'margin_top_min'] +
          'px !important;}}'
        this.sheet.innerHTML +=
          '@media screen and (min-width: 1200px){' +
          classes +
          ' {margin-top: ' +
          s[prefix + 'margin_top_max'] +
          'px !important;}}'
      }
      if (s[prefix + 'margin_bottom_max']) {
        this.sheet.innerHTML +=
          '@media screen and (max-width: 400px) {' +
          classes +
          ' {margin-bottom: ' +
          s[prefix + 'margin_bottom_min'] +
          'px !important;}}'
        this.sheet.innerHTML +=
          '@media screen and (min-width: 1200px){' +
          classes +
          ' {margin-bottom: ' +
          s[prefix + 'margin_bottom_max'] +
          'px !important;}}'
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  transition: opacity 200ms;
  display: flex;
  min-height: 100%;
  flex-direction: column;

  &.site-loaded {
    opacity: 1;
  }

  &.loading {
    cursor: progress;
  }
}

.page-container {
  position: relative;
  min-height: 100vh;
  transition: all 200ms;

  .menu-hover & {
    opacity: 0.5;
  }
}

.wrapper {
  @include wrapper;
}

.container {
  @include grid-container-responsive();
}
article {
  z-index: 2;
  position: relative;
}

.component-fade-enter-active {
  z-index: 3;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
.component-fade-leave-active {
  transition: none;
  animation-fill-mode: forwards;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}
.component-fade-enter,
.component-fade-leave,
.component-fade-leave-to {
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.component-slide-enter-active,
.component-slide-leave-active {
  transition: transform 0.5s ease;
}
.component-slide-enter {
  transform: translateY(100vh);
}

.component-slide-leave-to {
  transform: translateY(-10vh);
}

.enable-photoswipe {
  cursor: pointer;
}

.before-enter,
.enter {
  &[animation-ease='easeOutSine'] {
    transition-timing-function: $easeOutSine;
  }
  &[animation-ease='easeOutQuad'] {
    transition-timing-function: $easeOutQuad;
  }
  &[animation-ease='easeOutQuart'] {
    transition-timing-function: $easeOutQuart;
  }
  &[animation-ease='easeOutQuint'] {
    transition-timing-function: $easeOutQuint;
  }
  &[animation-ease='easeOutExpo'] {
    transition-timing-function: $easeOutExpo;
  }
  &[animation-ease='easeOutCirc'] {
    transition-timing-function: $easeOutCirc;
  }
  &[animation-ease='easeOutBack'] {
    transition-timing-function: $easeOutBack;
  }

  &[animation-type='fadein'] {
    transition-property: opacity;
  }

  &[animation-type='slideup'] {
    transition-property: transform, opacity;
  }
}

.before-enter {
  opacity: 0;

  .tns-inner &,
  .publication & {
    opacity: 1;
    transform: translate(0, 0) !important;
  }
}
.enter {
  opacity: 1;
  transform: translate(0, 0) !important;
}
.before-enter-fade {
  opacity: 0;
  transition: opacity 500ms;
}
.enter-fade {
  opacity: 1;
  transition: opacity 500ms;
}
.Cookie {
  width: 80% !important;
  max-width: 980px;
  margin: 0 auto;
  bottom: 30px !important;
  padding: 30px !important;

  @include media($mobile) {
    width: 95% !important;
    padding: 5px 20px !important;
    bottom: 15px !important;
  }

  .cookie-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @include media($mobile) {
      display: block;
    }

    .buttons {
      display: flex;
      align-items: center;
    }

    .title {
      @include fontsize(20);
      font-weight: bold;
      margin-bottom: 10px;
    }
    .description {
      @include fontsize(15);
    }

    a.simple {
      font-size: 15px !important;
      line-height: 20px !important;

      @include media($mobile) {
        margin-left: 20px;
      }
    }

    a {
      white-space: nowrap;
      margin-left: 20px;

      @include media($mobile) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
.page-enter-active,
.page-leave-active {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
</style>
